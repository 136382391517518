import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Observable,
  FetchResult,
  from,
  gql,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";
import Cookies from "js-cookie";

import { clientHashes } from "@207inc/graphql-persisted-queries";
import { usePregeneratedHashes as pregeneratedHashes } from "graphql-codegen-persisted-query-ids/lib/apollo";
import { LOCALSTORAGE_KEYS } from "~/constants/todocu-supporter";

import {
  RefreshApolloTokenDocument,
  RefreshApolloTokenMutation,
  RefreshApolloTokenMutationVariables,
} from "./apolloClient.generated";

const REFRESH_MUTATION = gql`
  mutation RefreshApolloToken {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;

export const persistedQueryLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
  generateHash: pregeneratedHashes(clientHashes),
});

export const REFRESH_TOKEN_KEY = "refreshToken";
export const ACCESS_TOKEN_KEY = "accessToken";

let token: string | null = null;
export const getAccessToken = () => {
  return token ?? Cookies.get(ACCESS_TOKEN_KEY);
};
export const updateAccessToken = (newAccessToken: string | null) => {
  if (newAccessToken) {
    token = newAccessToken;
    Cookies.set(ACCESS_TOKEN_KEY, newAccessToken, { secure: true });
  } else {
    token = null;
    Cookies.remove(ACCESS_TOKEN_KEY);
  }
};
export const getRefreshToken = () => {
  return window?.localStorage.getItem(REFRESH_TOKEN_KEY);
};
export const updateRefreshToken = (newRefreshToken: string | null) => {
  if (newRefreshToken) {
    window?.localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
  } else {
    window?.localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};
const authLink = setContext(async (_, previousContext) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    token = accessToken;
    return {
      ...previousContext,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...previousContext.headers,
      },
    };
  }
  return previousContext;
});
const companyDeliverAuthLink = setContext(async (_, previousContext) => {
  const companyId =
    window.localStorage.getItem(LOCALSTORAGE_KEYS.currentCompanyId) ??
    undefined;
  /**
   * NOTE: Android WebviewでCookieの保存がされないので、localStorageから取得する
   * @see https://github.com/react-native-webview/react-native-webview/issues/2643
   */
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    token = accessToken;
    return {
      ...previousContext,
      headers: {
        "x-company-id": companyId,
        Authorization: `Bearer ${accessToken}`,
        ...previousContext.headers,
      },
    };
  }
  return previousContext;
});
const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation, response }) => {
    const error = graphQLErrors?.at(0);
    if (error) {
      // Unauthorizedはguardで処理しているので、extensionがない
      if (error.message === "Unauthorized") {
        if (operation.operationName == "RefreshApolloToken") return;
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          updateAccessToken(null);
          updateRefreshToken(null);
          return;
        }
        const observable = new Observable<
          FetchResult<
            RefreshApolloTokenMutation,
            Record<string, any>,
            Record<string, any>
          >
        >((observer) => {
          client
            .mutate<
              RefreshApolloTokenMutation,
              RefreshApolloTokenMutationVariables
            >({
              mutation: RefreshApolloTokenDocument,
              context: {
                headers: {
                  authorization: `Bearer ${refreshToken}`,
                },
              },
            })
            .then((data) => {
              const accessToken = data.data?.refreshToken?.accessToken ?? null;
              const refreshToken =
                data.data?.refreshToken?.refreshToken ?? null;
              updateAccessToken(accessToken);
              updateRefreshToken(refreshToken);
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              return forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch((e) => {
              updateAccessToken(null);
              updateRefreshToken(null);
              observer.error(e);
            });
        });
        return observable;
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);
const httpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_BASE_URL}/graphql`,
});

/**
 * browser only
 */
const client = new ApolloClient({
  link: from([errorLink, authLink, persistedQueryLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === "development",
});

export function makeClient() {
  const httpLink = new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_BASE_URL}/graphql`,
  });

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : ApolloLink.from([errorLink, authLink, persistedQueryLink, httpLink]),
  });
}

/**
 * pure client
 */
export const deliverClient = new ApolloClient({
  link: from([persistedQueryLink, httpLink]),
  cache: new InMemoryCache(),
});
/**
 * browser only
 */
export const deliverAuthorizedClient = new ApolloClient({
  link: from([errorLink, companyDeliverAuthLink, persistedQueryLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
